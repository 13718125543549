<template>
  <div class="bookingOrder">
    <el-card class="box-card father-card" shadow="never">
      <el-row class="btns">
        <el-form ref="form" :model="form" inline>
          <el-form-item label="订单编号">
            <el-input v-model="form.orderNo" placeholder="请输入订单编号" clearable></el-input>
          </el-form-item>
          <!-- 按钮区域 -->
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="search()">搜索</el-button>
          </el-form-item>
        </el-form>
      </el-row>
      <el-row class="tab">
        <!-- 表格区域 -->
        <el-table
          ref="multipleTable"
          :data="List"
          tooltip-effect="dark"
          style="width: 100%"
          highlight-current-row
          stripe
          border
          :header-cell-style="rowClass"
        >
          <el-table-column prop="orderNo" label="订单编号" align="center"></el-table-column>
          <el-table-column prop="orderDate" label="订单时间" align="center"></el-table-column>
          <el-table-column prop="orderType" label="订单类型" align="center"></el-table-column>
          <el-table-column prop="orderReserveDate" label="预约时间" align="center"></el-table-column>
          <el-table-column prop="transferVehicle" label="转运类型" align="center"></el-table-column>
          <el-table-column prop="orderPredictFee" label="预估费用" align="center"></el-table-column>
          <el-table-column prop="orderPredictPay" label="预付费用" align="center"></el-table-column>
          <el-table-column prop="orderFactFee" label="实际费用" align="center"></el-table-column>
          <el-table-column label="详情" align="center">
            <template slot-scope="scope">
              <el-button size="mini" @click="look(scope.row)" type="success">查看详情</el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handlePageNumChange"
          :current-page="paging.pageNo"
          :page-sizes="[5, 10, 20, 50]"
          :page-size="paging.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
        <!-- 订单详情弹出框 -->
        <el-dialog :visible.sync="orderDialogFormVisible" class="order-dialog">
          <span slot="title" class="dialog-Title">即时订单详情</span>
          <el-form :model="orderForm" inline ref="orderForm"></el-form>
          <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="orderDialogFormVisible=false">确 认</el-button>
          </div>
        </el-dialog>
      </el-row>
    </el-card>
  </div>
</template>

<script>

export default {
  name: 'bookingOrder',
  components: {},
  data() {
    return {
      List: [], // 表格数据源
      form: { orderNo: '' }, // 搜索表单
      paging: {
        pageSize: 10, // 每页显示数据条数
        PageNo: 1 // 当前页数
      }, // 分页表单
      total: 0, // 总的数据条数
      orderDialogFormVisible: false,
      orderForm: {}
    }
  },
  created() {
    this.getOrderList()
  },
  methods: {
    // 给表格头部设定背景色
    rowClass({ row, rowIndex }) {
      return 'background:#e4eaec'
    },
    // 切换每页显示数量时触发
    async handleSizeChange(newSize) {
      this.paging.pageSize = newSize
      this.getOrderList()
    },
    // 切换页码时触发
    async handlePageNumChange(PageNum) {
      this.paging.PageNo = PageNum
      this.getOrderList()
    },
    // 获取预约订单
    async getOrderList() {
      const { data: res } = await this.$http.post('/userManageServer/pay/selectOrderList', {
        "pageSize": this.paging.pageSize,
        "pageNo": this.paging.PageNo,
        "orderType": '1',
        "status": '7'
      })
      if (res.statusCode !== "200") return this.$message.error('获取订单列表失败')
      this.List = res.data
      this.total = +res.pageBean.pageDataCount
      console.log(res);

    },
    // 点击搜索按钮触发
    async search() {
      // 判断有输入账号
      if (this.form.orderNo === '') {
        this.getOrderList()
      } else {
        const { data: res } = await this.$http.post('/userManageServer/pay/selectOrderList', {
          "pageSize": this.paging.pageSize,
          "pageNo": this.paging.PageNo,
          "orderType": '1',
          "status": '7',
          "orderNo": this.form.orderNo
        })
        if (res.statusCode !== "200") return this.$message.error('获取订单列表失败')
        this.List = res.data
        this.total = +res.pageBean.pageDataCount
        console.log(res);
      }

    },
    // 点击查看详情按钮触发
    look(v) {
      this.orderForm = v
      this.orderDialogFormVisible = true
    }
  }

}
</script>
<style lang="less" scoped>
.bookingOrder {
  width: 100%;
  height: 100%;
  .father-card {
    .btns {
      margin-bottom: 10px;
    }
    .tab {
      .el-pagination {
        margin-bottom: 0.25rem;
        margin-top: 0.25rem;
        margin-right: 0.25rem;
        text-align: right;
      }
      .order-dialog {
        .dialog-Title {
          color: #0097fe;
          font-size: 20px;
          font-weight: 700;
        }
        /deep/ .el-form {
          .subtitle {
            color: #0097fe;
            font-size: 14px;
            font-weight: 700;
            // margin: 15px 0;
            margin-bottom: 20px;
            padding-left: 6px;
            border-left: 5px solid #0097fe;
          }
          .el-row {
            // flex: 1;
            display: flex;
            // justify-content: space-between;
            .el-form-item {
              // width: 50%;
              flex: 1;
              display: flex;
              // text-align: left;
              .el-form-item__content {
                flex: 1;
                .el-input {
                  width: 100%;
                  .el-input__inner {
                    width: 100%;
                  }
                }
                .el-select {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
